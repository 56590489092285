<template>
  <div>微信登录</div>
</template>

<script>
import { GET } from "@/common/request";
import localStorageUtil from '@/common/userinfo'
const api = {
  getAuth: "/wechat/auth",
  addAndupdate: "/xkcwebApi/srMoveUser/addAndupdateSrMoveUser.htm"
};
export default {
  mounted() {
    if (!localStorageUtil.get("accessToken")) {
      if (Object.keys(this.$route.query).length === 0) {
        this.getAuthUrl();
      } else {
        this.$store
          .dispatch("wechatuser/getAuthUserInfo", this.$route.query)
          .then(res => {
            if (res.code === 200) {
              this.$router.push({
                path: "/"
              });
            }
          });
      }
    } else {
      this.$router.push({
        path: "/"
      });
    }
  },
  methods: {
    getAuthUrl() {
      GET(api.getAuth, {
        returnUrl: "suoer",
        // authCallbackUrl: "https://xingkecheng.suoeryun.com/getauth"
        authCallbackUrl: "http://xingkecheng.suoeryun.com/getauth"
      })
        .then(res => {
          location.replace(res.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>